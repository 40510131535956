import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RangeValue } from "rc-picker/lib/interface";
import { Button, Drawer, notification, Typography, InputNumber } from "antd";
import {
  AudioFile,
  audioFiles,
  AUDIOFILE_STATUS,
  FirebaseDocumentData,
  Revision,
  SubmissionDates,
  UserLog,
} from "../../api/db/repositories/audioFiles";
import FileTable from "../FileView/FileTable";
import { showError } from "../../api/utils";
import { useAuth } from "../../contexts/Auth";
import { ROLES } from "../../api/users";
import updateAnnotationFilePathByStatus, {
  generateGeckoFileURL,
  generatePrefixedName,
} from "../../api/azure/updateAnnotationFilePathByStatus";
import firebase from "firebase";
import blobService from "../../api/azure/blobService";
import CSVExporter from "../FileView/CSVExporter";
import DateRangeFilter from "../FileView/DateRangeFilter";
import FilenameFilter from "../FileView/FilenameFilter";
import { FilePath, filePaths } from "../../api/db/repositories/filePaths";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import { FileTag, fileTags } from "../../api/db/repositories/fileTags";
import RejectTranscriptionModal from "../FileView/RejectTranscriptionModal";
import { useForm } from "antd/lib/form/Form";
import FilterTags from "../FileView/FilterTags";
import { getTranscriptionVerificationStatus, postReviewedFile } from "../../api/pipeline/transcriptionVerification";
import { ClearOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce"

const DEFAULT_FILE_DURATION = 300;

export type FileChangePayload = Partial<
  Pick<AudioFile, "status" | "transcriber" | "reviewer" | "submissionDates" | "revisions" | "rejectionLogs" | "qcReviewer">
>;

export type TableFilters = Record<string, FilterValue | null>;

export interface ServerSideFilters {
  filters: TableFilters;
  sorter: SorterResult<AudioFile>;
  filenames?: string[] | null;
  creationDate?: RangeValue<Date>;
  pagination?: {
    direction: "next" | "prev";
    cursor: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
  };
  fileDuration: number;
}

const SERVER_SIDE_FILTERS = "serverSideFilters";
const CURRENT_STATEFUL_USER = "currentStatefullUser";

export const getFileStatus = (currentStatus: AUDIOFILE_STATUS) => {
  let stateMachine;
  const {
    NEW,
    TRANSCRIBE_IN_PROGRESS,
    WAITING_FOR_REVIEW,
    REVIEW_IN_PROGRESS,
    WAITING_FOR_ERROR_CORRECTION,
    ERROR_CORRECTION_IN_PROGRESS,
    REVIEWED,
    QC_IN_PROGRESS,
    PASSED_QC
  } = AUDIOFILE_STATUS;
  const isInErrorCorrectionMode = [WAITING_FOR_ERROR_CORRECTION, ERROR_CORRECTION_IN_PROGRESS].some(
    (s) => s === currentStatus
  );

  if (isInErrorCorrectionMode) {
    stateMachine = [WAITING_FOR_ERROR_CORRECTION, ERROR_CORRECTION_IN_PROGRESS, PASSED_QC];
  } else {
    stateMachine = [NEW, TRANSCRIBE_IN_PROGRESS, WAITING_FOR_REVIEW, REVIEW_IN_PROGRESS, REVIEWED, QC_IN_PROGRESS, PASSED_QC];
  }

  const currentStatusIndex = stateMachine.indexOf(currentStatus);
  const next = stateMachine[currentStatusIndex + 1] || stateMachine[stateMachine.length - 1];
  const previous = stateMachine[currentStatusIndex - 1] || stateMachine[0];
  return {
    next,
    previous,
  };
};

const getDefaultServerSideFilters = (): ServerSideFilters => ({
  filters: { filename: null, duration: null, transcriber: null, reviewer: null, status: null },
  sorter: {},
  fileDuration: DEFAULT_FILE_DURATION
});

const getServerSideFilters = (email: string | null): ServerSideFilters => {
  const defaultValues = getDefaultServerSideFilters();

  // @ts-ignore JSON.parse can handle nulls but the typing's wrong here
  const storedValues = JSON.parse(localStorage.getItem(SERVER_SIDE_FILTERS));
  if (storedValues === null || localStorage.getItem(CURRENT_STATEFUL_USER) !== email) return defaultValues;

  if (storedValues.creationDate) {
    // This needs reformatting since localStorage will save dates as string
    storedValues.creationDate = storedValues.creationDate.map((d: string) => new Date(d));
  }
  if (!storedValues.fileDuration) {
    storedValues.fileDuration = DEFAULT_FILE_DURATION;
  }

  return storedValues;
};

const Files = () => {
  const [documentRefData, setDocumentRefData] = useState<FirebaseDocumentData[]>([]);
  const [filteredFiles, setFilteredFiles] = useState<AudioFile[]>([]);
  const [paths, setPaths] = useState<FilePath[]>([]);
  const [fileTagList, setFileTagList] = useState<FileTag[]>([]);
  const [currentFileURL, setCurrentFileUrl] = useState<string>("");
  const [isDrawerVisible, setDrawerVisibility] = useState<boolean>(false);
  const [isTableLoading, setTableLoading] = useState<boolean>(false);
  const [pageCursors, setPageCursors] = useState<FirebaseDocumentData[]>([]);
  const { user, roles } = useAuth();
  const [rejectionFile, setRejectionFile] = useState<AudioFile | null>(null);
  const [isFileRejectionModalLoading, setIsFileRejectionModalLoading] = useState<boolean>(false);
  const [transcriptionRejectionForm] = useForm();
  const [serverSideFilters, setServerSideFilters] = useState<ServerSideFilters>(
    getServerSideFilters(user?.email as string)
  );

  const files = useMemo(() => {
    return documentRefData.map((datum) => ({
      id: datum.id,
      ...datum.data(),
    })) as AudioFile[];
  }, [documentRefData]);

  const updateFile = async (payload: FileChangePayload & { submissionDates?: SubmissionDates }, file: AudioFile) => {
    const { id } = file;
    setTableLoading(true);
    try {
      await audioFiles.update(id, payload);
    } catch (e) {
      showError(e as Error);
      setTableLoading(false);
      return;
    }

    if (payload.status) {
      const [filePrefix] = file!.filename.split(".");
      await updateAnnotationFilePathByStatus(filePrefix, payload.status).catch(showError);
    }

    // TODO: this is a temporary patch since not all data's coming from the subscription query -
    // This will only affect transcribers as they can create statuses which they are unable to see
    // i.e; rejected_by_transcriber
    const isTranscriber = roles.includes(ROLES.TRANSCRIBER) && roles.length === 1;
    if (isTranscriber) {
      await getData();
    }
    setTableLoading(false);
  };

  const handleNextPage = () => {
    const [file] = documentRefData.slice(-1);
    if (file) {
      const cursor = file;
      setPageCursors([...pageCursors, cursor]);
      setServerSideFilters({
        ...serverSideFilters,
        pagination: {
          direction: "next",
          cursor,
        },
      });
    }
  };

  const handlePrevPage = () => {
    // the last key is the current one, so we need the one before
    const cursor = pageCursors[pageCursors.length - 2];

    const filterClone = { ...serverSideFilters };
    if (!cursor) {
      delete filterClone.pagination;
    } else {
      filterClone.pagination = {
        direction: "prev",
        cursor,
      };
    }

    const cursorIndex = pageCursors.findIndex((pageCursor) => pageCursor.id === cursor?.id);
    setPageCursors(pageCursors.filter((_, index) => index <= cursorIndex));
    setServerSideFilters(filterClone);
  };

  const createRevision = (reviewer: UserLog, reviewerSubmissionDate: firebase.firestore.Timestamp): Revision => {
    return {
      reviewer,
      // TODO: this should be updated with the diff duration api when its ready
      submissionDates: {
        reviewer: reviewerSubmissionDate,
      },
    };
  };

  const handleFileClick = (file: AudioFile) => async () => {
    setCurrentFileUrl(generateGeckoFileURL(file, roles));
    setDrawerVisibility(true);

    const { ADMIN, VIEWER } = ROLES;
    const isViewOnlyRole = [ADMIN, VIEWER].some((role) => roles.includes(role));

    if (!isViewOnlyRole) {
      let updatePayload: FileChangePayload | null = null;
      const fileStatus = file.status;
      const nextStatus = getFileStatus(file.status).next;
      const userSnapshot = {
        email: user?.email!,
        displayName: user?.displayName!,
      };

      if (fileStatus === AUDIOFILE_STATUS.NEW && roles.includes(ROLES.TRANSCRIBER)) {
        updatePayload = {
          transcriber: userSnapshot,
          status: nextStatus,
        };
      } else if (file.status === AUDIOFILE_STATUS.WAITING_FOR_REVIEW && roles.includes(ROLES.REVIEWER)) {
        updatePayload = {
          reviewer: userSnapshot,
          status: nextStatus,
        };
      }
      else if(file.status === AUDIOFILE_STATUS.REVIEWED && roles.includes(ROLES.QC_REVIEWER)) {
        updatePayload = {
          qcReviewer: userSnapshot,
          status: nextStatus,
        };
      } else if (file.status === AUDIOFILE_STATUS.WAITING_FOR_ERROR_CORRECTION && roles.includes(ROLES.REVIEWER)) {
        const { revisions = [] } = file;
        updatePayload = {
          reviewer: userSnapshot,
          //@ts-ignore in favor of using dot notation
          "submissionDates.reviewer": firebase.firestore.FieldValue.delete() as never,
          status: nextStatus,
          revisions,
        };

        // Only create a revision if there was previously a reviewer
        // Since we've updated files manually, we have multiple files which are 'reviewed' but have no reviewer.
        if (file.reviewer) {
          const { reviewer, submissionDates } = file;
          updatePayload!.revisions = [...revisions, createRevision(reviewer, submissionDates!.reviewer!)];
        }
      }

      if (updatePayload) {
        await updateFile(updatePayload, file).catch(showError);
      }
    }
  };

  const handleTableChange = async (
    _pagination: TablePaginationConfig,
    filters: TableFilters,
    sorter: SorterResult<AudioFile>
  ) => {
    setPageCursors([]);
    setServerSideFilters({ ...serverSideFilters, filters, sorter });
  };

  const handleDrawerClose = () => {
    setDrawerVisibility(false);
  };

  const handleUnassign = async (file: AudioFile) => {
    const previousStatus = getFileStatus(file.status).previous;
    const {
      NEW,
      WAITING_FOR_REVIEW,
      TRANSCRIBE_IN_PROGRESS,
      REVIEW_IN_PROGRESS,
      WAITING_FOR_ERROR_CORRECTION,
    } = AUDIOFILE_STATUS;

    const payload: FileChangePayload = { status: previousStatus };
    let field: keyof AudioFile | null = null;

    if (previousStatus === NEW) {
      field = "transcriber";
    }

    if (previousStatus === WAITING_FOR_REVIEW || previousStatus === WAITING_FOR_ERROR_CORRECTION) {
      field = "reviewer";
    }

    if (field) {
      if (previousStatus === WAITING_FOR_ERROR_CORRECTION) {
        // Unassigning from error correction will revert to the original reviewer,
        // however once assigned, the original reviewer will be added as the last revision again
        // Although we'll have duplicity, we can use it to infer unassignments
        const lastRevision = file.revisions![file.revisions!.length - 1] || {
          reviewer: firebase.firestore.FieldValue.delete() as never,
          submissionDates: { reviewer: firebase.firestore.FieldValue.delete() as never },
        };
        payload.reviewer = lastRevision.reviewer;

        // @ts-ignore in favor of dot notation
        payload["submissionDates.reviewer"] = lastRevision.submissionDates.reviewer;
      } else {
        // deleting the transcriber/reviewer if we're hitting the proper status
        payload[field] = firebase.firestore.FieldValue.delete() as never;
      }
    }

    if (previousStatus === TRANSCRIBE_IN_PROGRESS) {
      // deleting submission dates if a submission was revoked
      payload.submissionDates = firebase.firestore.FieldValue.delete() as never;
    }

    // We don't delete the submission date in case of error correction as it should -
    // still be set for the original reviewer
    if (previousStatus === REVIEW_IN_PROGRESS) {
      // @ts-ignore since typing dot notation (i.e allowing every kind of string will) introduce instability
      payload["submissionDates.reviewer"] = firebase.firestore.FieldValue.delete() as never;
    }

    return updateFile(payload, file);
  };

  const handleSubmit = async (file: AudioFile) => {
    const [name] = file.filename.split(".");
    const filename = generatePrefixedName(name);
    const data = await blobService.getAzureFileData(filename);
    const discrepencyMap = await getTranscriptionVerificationStatus(data);
    const isReviewer = roles.includes(ROLES.REVIEWER);
    const hasWarnings = !!discrepencyMap.warnings.length;
    const shouldBlockWarnings = isReviewer && hasWarnings;
    const shouldDenySubmission = discrepencyMap.errors.length || shouldBlockWarnings;

    if (shouldDenySubmission) {
      notification["error"]({
        message: "Submission Aborted: Transcription errors found",
        description:
          "Transcription errors were found. Please try to save the file inside gecko in order to see the full error list",
      });
    } else {
      const nextStatus = getFileStatus(file.status).next;

      if ([AUDIOFILE_STATUS.REVIEWED, AUDIOFILE_STATUS.PASSED_QC].includes(nextStatus)) {
        const filePaths = filename.split("/");
        const parsedFilename = filePaths[filePaths.length - 1];
        postReviewedFile(parsedFilename, data, file.status, file.tags ? Object.keys(file.tags): []);
      }

      const submissionDates = { ...file.submissionDates };
      const date = new Date();

      // a user can be both a reviewer and a transcriber
      if (roles.includes(ROLES.TRANSCRIBER)) {
        // @ts-ignore
        submissionDates.transcriber = date;
      }

      if (roles.includes(ROLES.REVIEWER)) {
        // @ts-ignore
        submissionDates.reviewer = date;
      }

      if (roles.includes(ROLES.QC_REVIEWER)) {
        // @ts-ignore
        submissionDates.qcReviewer = date;
      }
      return updateFile({ status: nextStatus, submissionDates }, file);
    }
  };

  const showTranscriptionRejectionModal = (file: AudioFile) => () => {
    setRejectionFile(file);
  };

  const hideTranscriptionRejectionModal = () => {
    setRejectionFile(null);
  };

  const handleTranscriptionRejection = async () => {
    setIsFileRejectionModalLoading(true);
    const validatedFields = await transcriptionRejectionForm.validateFields().catch(console.error);

    if (validatedFields) {
      const { rejectionMessage = "", rejectionReason } = validatedFields;
      const { rejectionLogs = [] } = rejectionFile!;
      const isTranscriber = roles.includes(ROLES.TRANSCRIBER);
      const isReviewer = roles.includes(ROLES.REVIEWER);
      let nextStatus: AUDIOFILE_STATUS = rejectionFile!.status;

      if (isReviewer) {
        nextStatus = AUDIOFILE_STATUS.TRANSCRIBE_IN_PROGRESS;
      } else if (isTranscriber) {
        nextStatus = AUDIOFILE_STATUS.REJECTED_BY_TRANSCRIBER;
      }
      await updateFile(
        {
          rejectionLogs: [
            ...rejectionLogs,
            {
              reason: rejectionReason,
              message: rejectionMessage,
              rejectedBy: {
                email: user!.email!,
                displayName: user!.displayName!,
                roles: roles,
              },
              date: new Date(),
            },
          ],
          status: nextStatus,
          // Keeping the transcriber, but deleting their submission date.
          // @ts-ignore avoiding errors on dot notation
          "submissionDates.transcriber": firebase.firestore.FieldValue.delete() as never,
        },
        rejectionFile
      );
      notification["info"]({
        message: `File ${rejectionFile!.filename} Successfully rejected`,
      });

      transcriptionRejectionForm.resetFields();
      hideTranscriptionRejectionModal();
    }

    setIsFileRejectionModalLoading(false);
  };

  const reconcileTableData = useCallback(
    (updateData: FirebaseDocumentData) => {
      const clone = [...documentRefData];
      const fileIndex = clone.findIndex((oldFile) => oldFile.id === updateData.id);
      if (fileIndex > -1) {
        clone.splice(fileIndex, 1, updateData);
      }
      setDocumentRefData(clone);
    },
    [documentRefData]
  );

  const loadAudioFiles = async (roles: ROLES[], filters: ServerSideFilters) => {
    const rawDocs = await audioFiles.getRawDocs(roles, filters, serverSideFilters.fileDuration);
    setDocumentRefData(rawDocs);
  };

  const clearSearchByFilename = () => {
    setServerSideFilters(getServerSideFilters(user?.email as string));
  };

  const handleDateRangeFilter = (dateRange: RangeValue<Date>) => {
    setServerSideFilters({ ...serverSideFilters, creationDate: dateRange });
  };

  const clearDateRangeFilter = () => {
    setServerSideFilters({ ...serverSideFilters, creationDate: null });
  };

  const handleSearchByFilename = (filenames: string[]) => {
    setServerSideFilters({ ...getServerSideFilters(user?.email as string), filenames });
  };

  const handleFileDurationFilter = debounce((durationInMinutes: number) => setServerSideFilters({ ...serverSideFilters, fileDuration: durationInMinutes * 60 }), 300);

  // Persist serverSideFilters between sessions
  useEffect(() => {
    localStorage.setItem(SERVER_SIDE_FILTERS, JSON.stringify(serverSideFilters));
    localStorage.setItem(CURRENT_STATEFUL_USER, user?.email as string);
  }, [serverSideFilters, user?.email]);

  useEffect(() => {
    // This effect is a client filter for transcribers to not be able to see other transcribers work
    // It is scoped here, because the pagination is still done with the original files as it requires -
    // predictable order in order to paginate from the actual last and first positions and not the client filtered ones.
    // You cannot create these filters on the server side as firestore doesn't support 'find a non existing value' -
    // which is important since transcribers should see unassigned jobs.
    const uniqueRoles = new Set(roles);
    const isTranscriber = uniqueRoles.has(ROLES.TRANSCRIBER) && uniqueRoles.size === 1;
    if (isTranscriber) {
      setFilteredFiles(files.filter((file) => file.transcriber?.email === user?.email || !file.transcriber));
    } else {
      setFilteredFiles(files);
    }
  }, [files, roles, user]);

  // subscribing to changes based on the most recent query
  useEffect(() => {
    //const audioFileQuery = audioFiles.getQuery(roles, serverSideFilters);
    const { getQueries, subscribeToFileChanges } = audioFiles;

    const subscribeToLiveChanges = (query: firebase.firestore.Query) =>
      subscribeToFileChanges(query, (doc) => {
        reconcileTableData(doc);
      });
    const audioFilesQueries = getQueries(roles, serverSideFilters);
    const unsubscribers = audioFilesQueries.map((query) => subscribeToLiveChanges(query));

    return () => unsubscribers.forEach((unsubscriber) => unsubscriber());
  }, [reconcileTableData, serverSideFilters, roles]);

  const getData = useCallback(async () => {
    setTableLoading(true);
    try {
      const paths = await filePaths.getAll();
      setPaths(paths);
      const tags = await fileTags.getAll();
      setFileTagList(tags);
      await loadAudioFiles(roles, serverSideFilters);
    } catch (e) {
      showError(e as Error);
    }
    setTableLoading(false);
  }, [roles, serverSideFilters]);

  // loading data based on the current state
  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user, getData]);

  const clearAllFilters = () => {
    clearDateRangeFilter();
    setServerSideFilters(getDefaultServerSideFilters());
  };

  const { creationDate } = serverSideFilters;
  return (
    <>
      <Typography.Title level={2}> Files </Typography.Title>
      {roles.includes(ROLES.ADMIN) && (
        <div style={{ marginBottom: "12px" }}>
          <CSVExporter exportButtonProps={{ disabled: isTableLoading }} />
        </div>
      )}
      <DateRangeFilter
        isLoading={false}
        onClick={handleDateRangeFilter}
        onClear={clearDateRangeFilter}
        dateRange={creationDate}
      />
      <FilenameFilter
        onClear={clearSearchByFilename}
        onClick={handleSearchByFilename}
        values={serverSideFilters.filenames}
      />
      {creationDate && <FilterTags dateRange={creationDate} onDateTagClose={clearDateRangeFilter} />}
      <Typography.Paragraph>
        File Duration (minutes) <InputNumber min={1} value={serverSideFilters?.fileDuration / 60} onChange={handleFileDurationFilter}/>
      </Typography.Paragraph>
      <Button type="dashed" style={{ marginBottom: "12px" }} onClick={clearAllFilters} icon={<ClearOutlined />}>
        Clear Filters
      </Button>
      <FileTable
        files={filteredFiles}
        paths={paths}
        fileTags={fileTagList}
        onFileClick={handleFileClick}
        onUnassign={handleUnassign}
        onTranscriptionRejectionClick={showTranscriptionRejectionModal}
        onSubmit={handleSubmit}
        loading={isTableLoading}
        onChange={handleTableChange}
        onNextPage={handleNextPage}
        onPrevPage={handlePrevPage}
        serverSideFilters={serverSideFilters}
      />
      <Drawer
        className="iframe-drawer"
        visible={isDrawerVisible}
        onClose={handleDrawerClose}
        closable={false}
        width="75%"
      >
        <iframe src={currentFileURL} title="gecko-frame" className="gecko-iframe" width="100%" height="100%" />
      </Drawer>
      <RejectTranscriptionModal
        confirmLoading={isFileRejectionModalLoading}
        onReject={handleTranscriptionRejection}
        onCancel={hideTranscriptionRejectionModal}
        file={rejectionFile}
        form={transcriptionRejectionForm}
      />
    </>
  );
};

export default Files;
