import React, { useEffect, useState } from "react";
import { Table, Button, Divider, Space, Tag, Typography, Spin } from "antd";
import { AudioFile, AUDIOFILE_STATUS } from "../../api/db/repositories/audioFiles";
import tagFactory from "../tagFactory";
import { convertToDuration } from "../utils";
import { ROLES } from "../../api/users";
import { useAuth } from "../../contexts/Auth";
import { FilePath } from "../../api/db/repositories/filePaths";
import { TablePaginationConfig } from "antd/lib/table/interface";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { ServerSideFilters, TableFilters } from "../Pages/Files";
import { FileTag } from "../../api/db/repositories/fileTags";
import { generateGeckoFileURL } from "../../api/azure/updateAnnotationFilePathByStatus";

type ActionCallback = (file: AudioFile) => Promise<void>;

interface FileViewProps {
  files: AudioFile[];
  paths: FilePath[];
  fileTags: FileTag[];
  onFileClick: (file: AudioFile) => (e: React.SyntheticEvent) => void;
  onUnassign: ActionCallback;
  onSubmit: ActionCallback;
  onTranscriptionRejectionClick: (file: AudioFile) => () => void;
  onChange: (pagination: TablePaginationConfig, filters: TableFilters, sorter: any) => void;
  loading?: boolean;
  onNextPage: () => void;
  onPrevPage: () => void;
  serverSideFilters: ServerSideFilters;
}

export interface FileLink {
  href: string;
  title: React.ReactNode;
}

const generateUniqueUserFilter = (files: AudioFile[], key: keyof Pick<AudioFile, "reviewer" | "transcriber" | "qcReviewer">) => {
  const values = files.map((file) => file[key]?.email ?? "");
  const uniqueEmails = new Set(values);
  const filters = Array.from(uniqueEmails)
    .filter((email) => email.length > 0)
    .sort()
    .map((email) => ({
      text: email,
      value: email.toLowerCase(),
    }));

  return filters;
};

const generateEnumFilter = (enumProp: Record<any, any>) => {
  const filters = Object.entries(enumProp).map(([text, value]) => ({
    text,
    value,
  }));

  return filters;
};

const TableOffline = () => (
  <div style={{ textAlign: "center" }}>
    <Spin tip="Waiting for internet connection" />
  </div>
);

const FileTable = ({
  files,
  paths,
  fileTags,
  onFileClick,
  onUnassign,
  onSubmit,
  onTranscriptionRejectionClick,
  onChange,
  loading,
  onNextPage,
  onPrevPage,
  serverSideFilters,
}: FileViewProps) => {
  const [filteredFiles, setFilteredFiles] = useState<AudioFile[]>(files);
  const [lockedFile, setLockedFile] = useState<AudioFile | null>(null);
  const { user, roles } = useAuth();
  const [isOffline, setIsOffline] = useState<boolean>(false);

  useEffect(() => {
    const setOfflineState = () => setIsOffline(true);
    const setOnlineState = () => setIsOffline(false);
    globalThis.addEventListener("offline", setOfflineState);
    globalThis.addEventListener("online", setOnlineState);

    return () => {
      globalThis.removeEventListener("offline", setOfflineState);
      globalThis.removeEventListener("online", setOnlineState);
    };
  }, []);

  useEffect(() => {
    // This is not really required but kept as a placeholder for now
    setFilteredFiles(files);
  }, [files, roles]);

  const shouldRenderActionButtons = (file: AudioFile) => {
    const {
      TRANSCRIBE_IN_PROGRESS,
      WAITING_FOR_REVIEW,
      REVIEW_IN_PROGRESS,
      ERROR_CORRECTION_IN_PROGRESS,
      REVIEWED,
      QC_IN_PROGRESS,
      PASSED_QC,
      NEW
    } = AUDIOFILE_STATUS;
    const { TRANSCRIBER, REVIEWER, QC_REVIEWER, ADMIN } = ROLES;
    const { status, transcriber, reviewer, qcReviewer } = file;
    const hasTranscriberRole = roles.includes(TRANSCRIBER);
    const isTranscriberStatus = status === TRANSCRIBE_IN_PROGRESS || status === WAITING_FOR_REVIEW;
    const isCurrentTranscriber = user?.email === transcriber?.email;
    const isTranscriberAllowed = isCurrentTranscriber && hasTranscriberRole;
    const hasReviewerRole = roles.includes(REVIEWER);
    const hasQcReviewerRole = roles.includes(QC_REVIEWER);
    const isCurrentReviewer = user?.email === reviewer?.email;
    const isCurrentQcReviewer = user?.email === qcReviewer?.email

    const isReviewerAllowed = isCurrentReviewer && hasReviewerRole;
    const isReviewerStatus = status === REVIEW_IN_PROGRESS || status === ERROR_CORRECTION_IN_PROGRESS;

    const isQcReviewerStatus = status === QC_IN_PROGRESS;

    const isQcReviewerAllowed = isCurrentQcReviewer && hasQcReviewerRole;

    const canTranscriberUnassign = isTranscriberAllowed && isTranscriberStatus;
    const canTranscriberSubmit = isTranscriberAllowed && status === TRANSCRIBE_IN_PROGRESS;

    const canReviewerUnassign = isReviewerAllowed && (isReviewerStatus || status === REVIEWED);
    const canReviewerSubmit = isReviewerAllowed && isReviewerStatus;

    const canQcReviewerUnassign = isQcReviewerAllowed && (isQcReviewerStatus || PASSED_QC === status);
    const canQcReviewerSubmit = isQcReviewerAllowed && isQcReviewerStatus;

    const isAdminOrQcReviewer = (roles.includes(ROLES.ADMIN) || roles.includes(ROLES.QC_REVIEWER)) && status !== NEW;

    const canUnassign = canTranscriberUnassign || canReviewerUnassign || canQcReviewerUnassign || isAdminOrQcReviewer;
    const canSubmit = canTranscriberSubmit || canReviewerSubmit || canQcReviewerSubmit;

    return {
      submit: canSubmit,
      unassign: canUnassign,
    };
  };

  const withAction = (file: AudioFile, callback: ActionCallback) => async () => {
    setLockedFile(file);
    await callback(file);
    setLockedFile(null);
  };

  const columns = [
    {
      title: "Filename",
      dataIndex: "filename",
      key: "filename",
      filterMultiple: false,
      filters: paths.map(({ id, path }) => ({
        text: path,
        value: path,
        key: id,
      })),
      //onFilter: (): boolean => true,
      filteredValue: serverSideFilters.filters.filename?.length ? serverSideFilters.filters.filename : void 0,
      render: (name: string, file: AudioFile) => {
        const doesActionPerformed = lockedFile?.id === file.id;
        return doesActionPerformed ? (
          <Spin tip="Processing..." />
        ) : (
          <>
            <Typography.Link copyable={{ text: globalThis?.location.host + generateGeckoFileURL(file, roles) }}>
              <span onClick={onFileClick(file)}>{name}</span>
            </Typography.Link>

            <div>
              {shouldRenderActionButtons(file).unassign && (
                <>
                  <Button type="primary" onClick={withAction(file, onUnassign)} size="small">
                    Unassign
                  </Button>
                  <Divider type="vertical" />
                </>
              )}
              {shouldRenderActionButtons(file).submit && (
                <>
                  <Button type="primary" size="small" onClick={withAction(file, onSubmit)}>
                    Submit
                  </Button>
                  <Divider type="vertical" />
                  <Button type="primary" size="small" danger onClick={onTranscriptionRejectionClick(file)}>
                    Reject
                  </Button>
                </>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (duration: number) => convertToDuration(duration),
      sorter: true,
      sortOrder: serverSideFilters.sorter?.field === "duration" ? serverSideFilters.sorter.order : void 0,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: tagFactory,
      filters: generateEnumFilter(AUDIOFILE_STATUS),
      filterMultiple: false,
      filteredValue: serverSideFilters.filters.status?.length ? serverSideFilters.filters.status : void 0,
    },
    {
      title: "Transcriber",
      dataIndex: "transcriber",
      key: "transcriber",
      filters: generateUniqueUserFilter(filteredFiles, "transcriber"),
      filterMultiple: false,
      render: (user: AudioFile["transcriber"] | null) => user?.email,
      filteredValue: serverSideFilters.filters.transcriber && serverSideFilters.filters.transcriber,
    },
    {
      title: "Reviewer",
      dataIndex: "reviewer",
      key: "reviewer",
      filterMultiple: false,
      filters: generateUniqueUserFilter(filteredFiles, "reviewer"),
      render: (user: AudioFile["reviewer"] | null) => user?.email,
      filteredValue: serverSideFilters.filters.reviewer && serverSideFilters.filters.reviewer,
    },
    {
      title: "QC Reviewer",
      dataIndex: "qcReviewer",
      key: "qcReviewer",
      filterMultiple: false,
      filters: generateUniqueUserFilter(filteredFiles, "qcReviewer"),
      render: (user: AudioFile["qcReviewer"] | null) => user?.email,
      filteredValue: serverSideFilters.filters.qcReviewer && serverSideFilters.filters.qcReviewer,
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      filterMultiple: false,
      filters: fileTags.map(({ name }) => ({
        text: name,
        key: name,
        value: name,
      })),
      render: (tags: AudioFile["tags"]) => {
        if (!tags) return null;
        return Object.keys(tags).map((tag: string, key: number) => (
          <Tag color="gold" key={key}>
            {tag}
          </Tag>
        ));
      },
      filteredValue: serverSideFilters.filters.tags && serverSideFilters.filters.tags,
    },
  ];

  return isOffline ? (
    <TableOffline />
  ) : (
    <>
      <Table
        rowKey="id"
        dataSource={filteredFiles}
        columns={columns}
        loading={loading}
        onChange={onChange}
        className="audio-file-table"
        pagination={false}
      />
      <Space direction="horizontal" style={{ float: "right", marginTop: "10px" }}>
        <Button tabIndex={-1} size="small" onClick={onPrevPage}>
          <LeftOutlined />
        </Button>
        <Button tabIndex={-1} size="small" onClick={onNextPage}>
          <RightOutlined />
        </Button>
      </Space>
    </>
  );
};

export default FileTable;
